.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f8fb;
}

h1 {
  margin-bottom: 20px;
}

.options-list {
  list-style-type: none;
  padding: 0;
}

.options-list li {
  margin: 5px 0;
}

.options-list button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.options-list button:hover {
  background-color: #0056b3;
}