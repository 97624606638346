.details-form {
  max-width: 700px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.botBtn{
  border-radius: 10px;;
  background-color: #0e82f6;
  color: #fff;
}
.details-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.details-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.details-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  color: #000000;
}

.details-form select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

.details-form button {
  width: 100%;
  padding: 10px;
  /* background-color: #80FF7A; */
  border: none;
  /* border-radius: 3px; */
  /* color: black; */
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #69b3fd;
  color: #000000;
  font-weight: 600;
}
.sc-bZQynM{
  padding: 6px;
}
.details-form button:hover {
  background-color: #0d82f7;
  color: #ffffff;
  font-weight: bolder;
}

.details-form input[type="date"],
.details-form input[type="time"] {
  width: calc(100% - 16px);
}

.details-form input:focus {
  border-color: #80FF7A;
  outline: none;
}

.details-form .error {
  color: red;
  margin-bottom: 15px;
  font-size: 0.9em;
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  color: black;
}