* {
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

.header {
    background-color: #09091F;
}

.header_nav {
    position: sticky;
    z-index: 1;
    top: 10;
}

.footer {
    background-color: #09091F;
    z-index: 3;
}

ul {
    list-style: none;
}

.navbar-toggler-icon {
    color: white !important;
}

.active-link {
    /* text-decoration: underline; */
    font-weight: bold;
    background: orangered;
    padding: 10px 15px;
    border-radius: 10px;
}

/* .setmainBanner {
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    background: url('../assets/1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
} */

.body-data {
    overflow: hidden;
}

.appt_cont {
    overflow: hidden;
}



.video-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-align: center;
    z-index: 2;
    /* Ensures the title is above the video */
}

.title .consultation_title {
    -webkit-text-stroke: 2px rgb(253, 252, 251);
    color: rgb(248, 85, 10);
    font-family: cursive;
}

.home_cons {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    font-size: 18px;
    font-weight: bolder;
    color: white;
}

/* 
.second-banner {
    background: url('../assets/second-bg.jpg') center no-repeat;
    background-size: cover;
} */

.terms-details,
.terms-details-ol,
.terms-details-li {
    margin-top: 10px;

}
.terms-details-li{
    list-style: circle;
}

.terms-details {
    color: black;
}

button {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

button:hover,
button:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
    line-height: 1.5;
    font-weight: 400;
    font-family: "Poppins", Arial, sans-serif;
    color: #000;
}

.ftco-section {
    padding: 0;
}

.ftco-no-pt {
    padding-top: 0;
}

.ftco-no-pb {
    padding-bottom: 0;
}

.heading-section {
    font-size: 28px;
}

.heading-section small {
    font-size: 18px;
}

.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

ul.ks-cboxtags {
    list-style: none;
}

ul.ks-cboxtags li {
    display: block;
}

ul.ks-cboxtags li label {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    border-radius: 4px;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* -webkit-tap-highlight-color: transparent; */
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    display: block;
}

ul.ks-cboxtags li label {
    padding: 10px 20px;
    cursor: pointer;
}

ul.ks-cboxtags li label::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "FontAwesome";
    font-weight: 900;
    font-size: 24px;
    padding: 4px;
    margin-right: 15px;
    /* content: "\f1db"; */
    color: rgba(0, 0, 0, 0.2);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    -o-transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    position: absolute;
    right: 40px;
    margin-top: -5px;
}

ul.ks-cboxtags li label span {
    background: #f9e090;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 30px;
    color: #fff;
    margin-left: 10px;
}

ul.ks-cboxtags li input[type="checkbox"]:checked+label::before {
    /* content: "\f058"; */
    -webkit-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    transform: rotate(-360deg);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    -o-transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    color: #fff;
}

ul.ks-cboxtags li input[type="checkbox"]:checked+label {
    border: 1px solid orange;
    /* background-color: #c400c6; */
    background-color: orange;
    color: #fff;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

ul.ks-cboxtags li input[type="checkbox"]:checked+label span {
    background: #ffffff;
    color: #000;
}

ul.ks-cboxtags li input[type="checkbox"] {
    display: absolute;
}

ul.ks-cboxtags li input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}



.hidden-checkbox {
    display: none;
}

.span {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
    cursor: pointer;
}

.span:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
}

.span img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

:checked+.span {
    border-color: #ddd;
}

:checked+.span:before {
    content: "✓";
    background-color: red;
    border: 1px solid orangered;
    transform: scale(1);
}

:checked+.span img {
    transform: scale(0.9);
}

.user_green {
    border: 4px solid lawngreen;
}

.user_green {
    content: "";
    width: 10px;
    height: 10px;
    background-color: green;
    /* position: absolute; */
}

.user_red {
    border: 4px solid orangered;
}

li {
    margin: 10px 0px;
}

.astrologer_online {
    border: 3px solid #08f108;
    border-radius: 50%;
    height: 100px;
    margin-top: 21px;
}

.astrologer_offline {
    border: 3px solid #ff0000;
    border-radius: 50%;
    height: 100px;
    margin-top: 21px;
}


@media(max-width: 767.98px) {
    .video_bg {

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.ovr_lap {
    position: fixed;
    bottom: 80px;
    /* Position the text above the chatbot icon */
    right: 90px;
    /* Adjust the right position to align with the chatbot icon */
    /* background-color: #4682b4; */
    color: rgb(198, 4, 246);
    padding: 8px 12px;
    border-radius: 20px;
    /* font-size: 16px; */
    font-weight: bold;
    z-index: 1;
    /* Ensure it stays on top */
    font-size: 24px;
}

.ovr_lap_text {
    margin: 0;

}

.ovr_lap {
    z-index: 100;

}

.bLFsWh {
    z-index: 10000;

}

.curved-text {
    position: absolute;
    top: -43px;
    left: -90px;
    width: 348px;
    height: 173px;
    pointer-events: none;
    /* Ensure the text doesn't interfere with clicking the icon */
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.curved-text text {
    font-size: 15px;
    font-weight: bold;
    fill: #045390;
    /* Text color */
    animation: bounce 2s infinite;
    /* Add bounce animation */
}

.curved-text path {
    stroke: transparent;
}

.Courses_page {
    width: 100%;
    height: 70vh;
    justify-content: center;
    align-items: center;
    background: url('../assets/1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.course_card {
    height: 350px;
}

.card_course_body {
    overflow-y: auto;
}

.course_box {
    margin: 10px;
    /* padding: 25px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid silver; */
    border-radius: 20px;

}

.course_body {
    width: 315px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    border: 0px solid #e5e4e4;
    padding: 29px 14px;
    height: 288px;
    box-shadow: 0 0px 21px -7px black;
    transition: transform 1s;
}

.course_body:hover {
    height: 288px;

    border-radius: 20px;
    /* border: 4px solid rgb(235, 176, 155); */
    padding: 50px 20px;
    box-shadow: 0 0px 21px -7px black;
    background: linear-gradient(270deg, rgba(244, 124, 54, 1) 3%, rgba(243, 164, 53, 1) 94%);
    color: white;
    /* transform: scale(1.2); */

}

.enq_fm_btn {
    background: linear-gradient(270deg, rgba(244, 124, 54, 1) 3%, rgba(243, 164, 53, 1) 94%);
    border: none;
    padding: 10px 30px;

}

.course_body:hover h4 {
    color: white;
}

.text-red {
    color: red;
}

.course_body button {
    background-color: transparent !important;
    color: #be1010;
    border: none;
    font-weight: bolder;
    font-size: 18px;
}

.course_body p {
    text-align: center;
    font-weight: 500;
}


/* Contact page csss */
.form-control {
    background-color: #fff;
    border: 1px solid #bfbaba !important;
    border-radius: -11px;
    color: var(--primary-color);
    font-size: 14px;
    height: 45px;
    line-height: 30px;
    outline: none;
    padding: 0 28px;
}

.form-control::-webkit-input-placeholder {
    color: #000 !important;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #000 !important;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #000 !important;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #000 !important;
    opacity: 1;
}

.form-control::placeholder {
    color: #000 !important;
    opacity: 1;
}

.contact_sideImg {
    /* background-image: url(../assets/img/.gif); */
    /* width: 10%; */
    /* height: 300px; */
    /* background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 30px;
box-shadow: 0px 5px 10px black; */
}

.contact_sideImg .img iframe {
    width: 100%;
    height: 400px;
}

@media (min-width: 320px) and (max-width: 479px) { /* Small devices like phones in portrait */
    .contact_sideImg .img {
        width: 300px;

    }
}

@media (min-width: 480px) and (max-width: 767px) { /* Medium devices like phones in landscape */
    .contact_sideImg .img {
        width: 420px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) { /* Tablets */
    .contact_sideImg .img {
        width: 420px;
        height: 400px;
    }
}

@media (min-width: 1024px) and (max-width: 1439px) { /* Laptops and desktops */
    .contact_sideImg .img {
        width: 500px;
        
    }
}

@media (min-width: 1440px) { /* Large screens */
    .contact_sideImg .img {
        width: 600px;
    }
}

.rsc-ts-bot {
    width: 420px;
}

.navbar-toggler-icon {
    background-color: #fff;
}

.formCheckBox {
    display: block;
    margin-bottom: .125rem;
    min-height: 1.5rem;
    padding-left: 0.5em;
}

ul.ks-cboxtags li .formCheckBox input[type=checkbox]:checked+label {
    background-color: orange;
    border: 1px solid orange;
    color: #fff;
    transition: all .2s;
    height: 35px;
    font-size: 15px;
    text-align: center;
}


ul.ks-cboxtags li label {
    -webkit-touch-callout: none;
    background-color: #ffffffe6;
    border: 1px solid #0000001a;
    border-radius: 4px;
    color: #000c;
    cursor: pointer;
    display: inline-block;
    display: block;
    font-size: 16px;
    padding: 4px 23px;
    transition: all .2s;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.newOne {
    border: 2px solid #FF6E42;
    padding: 20px;
    border-radius: 16px;
}

.social-media-icons {
    color: #fff;
}

.bNgTFH {
    margin: 0;
    color: white;
    font-size: 15px;
}

.form-check-input:checked {
    background-color: #ff6e42;
    border-color: #ff6e42;
}